import React, { useEffect, useState, useContext } from 'react'
import http from 'axios'
import { Col, Card, Row, Select } from 'components/Ui'
import { List as ReferementsList } from '../../Components/ReferementsTranslations'
import { SearchContext } from 'core/contexts/SearchContext'

function PageList() {
	const [items, setItems] = useState([])
	const [filtersValues, setFiltersValues] = useState({})
	const defaultFiltersValues = {}
	const { contextSearch } = useContext(SearchContext)
	const [referements, setReferments] = useState([])
	const [selectedReferment, setSelectedReferment] = useState('')

	useEffect(() => {
		loadReferments()
	}, [])

	useEffect(() => {
		if (selectedReferment !== '')
			handleList({
				refer_type_id: selectedReferment
			})
	}, [selectedReferment])

	const loadReferments = async() => {
		const referements = await http.get(`${window.__endpoint}/cha/referement_types/list?cols=name,refer_type_id&cha_flag=1&translatable=1`)
		setReferments(referements.data)
	}

	const handleList = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/cha/referement_types/get/list`, {
			params: filters
		})
		setItems(list.data)
	}

	const handleDelete = async(id) => {
		await http.delete(`${window.__endpoint}/prc/attributes/delete?attribute_id=${id}`)
		handleList(filtersValues)
	}

	const handleSetItems = items => {
		setItems(items)
	}

	const handleRefermentChange = (e) => {
		const refer_type_id = e.target.value
		setSelectedReferment(refer_type_id)
	}
	return <>
		<Row>
			<Col all={6}>
				<Select
					className="w-full"
					value={selectedReferment}
					onChange={handleRefermentChange}
					options={referements || []}
					optionValue={(o) => o.refer_type_id}
					optionLabel={(o) => o.referment_full_name}
					all={{
						label: 'Seleziona un riferimento'
					}}
				/>
			</Col>
		</Row>
		{selectedReferment != '' && <Card title="Attributi">
			<ReferementsList
				refer_type_id={selectedReferment}
				defaultFiltersValues={defaultFiltersValues}
				filtersValues={filtersValues}
				items={items}
				onList={handleList}
				onDelete={handleDelete}
				handleSetItems={handleSetItems}
			/>
		</Card>}
	</>
}

export default PageList
