import React, { useEffect, useState } from 'react'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select, HTMLEditor } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ editItem, setEditItem, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const referement_types = await http.get(`${window.__endpoint}/cha/referement_types/list?cols=refer_type_id,referment_full_name&order=referment_full_name`)

		setSelectOptions({
			referement_types: referement_types.data,
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name', 'refer_type_id', 'profile_active_flag', 'profile_mail_flag']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Attivo di default (report standard) */}
					<Col all={6}>
						<FormGroup
							name="default_active_flag"
							label="Attivo di default su ogni ambiente per ogni organizzazione"
							validationState={validator.validationState.default_active_flag}
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.default_active_flag == 1}
							/>
						</FormGroup>
					</Col>
					{/* Visibile sulle impostazioni */}
					<Col all={6}>
						<FormGroup
							name="user_visibility_flag"
							label="Visibile sulle impostazioni"
							validationState={validator.validationState.user_visibility_flag}
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.user_visibility_flag == 1}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Nome */}
					<Col all={12} sm={12}>
						<FormGroup
							name="name"
							label="Nome"
							validationState={validator.validationState.name}
						>
							<InputText
								value={editItem.name || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Descrizione */}
					<Col all={12}>
						<FormGroup
							name="description"
							label="Descrizione"
							validationState={validator.validationState.description}
						>
							<InputTextarea
								type="text"
								row={6}
								value={editItem.description || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Referment types */}
					<Col all={6}>
						<FormGroup
							name="refer_type_id"
							label="Referment type"
							validationState={validator.validationState.refer_type_id}
						>
							<Select
								value={editItem.refer_type_id || ''}
								onChange={onChange}
								options={selectOptions.referement_types || []}
								optionValue={(o) => o.refer_type_id}
								optionLabel={(o) => o.referment_full_name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Procedura */}
					<Col all={6}>
						<FormGroup
							name="procedure_creation"
							label="Procedura"
							validationState={validator.validationState.procedure_creation}
						>
							<InputText
								value={editItem.procedure_creation || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Testo (non obbligatorio in quanto potrei avere delle notifiche dove ho solo il testo generato dallo script php) */}
					<Col all={12} sm={12}>
						<FormGroup
							name="text"
							label="Testo"
							validationState={validator.validationState.text}
						>
							<InputTextarea
								value={editItem.text || ''}
								onChange={onChange}
								rows={5}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Link */}
					<Col all={12} sm={12}>
						<FormGroup
							name="link"
							label="Link"
							validationState={validator.validationState.link}
						>
							<InputText
								value={editItem.link || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Profilo notifica (default) */}
					<Col all={6}>
						<FormGroup
							name="profile_active_flag"
							label="Ricezione della notifica"
							validationState={validator.validationState.profile_active_flag}
						>
							<Select
								value={editItem.profile_active_flag || ''}
								onChange={onChange}
								options={[
									{ optionValue: '0', optionLabel: 'Disabilitata' },
									{ optionValue: '1', optionLabel: 'Abilitata' },
								]}
								optionValue={(o) => o.optionValue}
								optionLabel={(o) => o.optionLabel}
							/>
						</FormGroup>
					</Col>
					{/* Profilo notifica (default) */}
					<Col all={6}>
						<FormGroup
							name="profile_mail_flag"
							label="Ricezione della mail"
							validationState={validator.validationState.profile_mail_flag}
						>
							<Select
								value={editItem.profile_mail_flag || ''}
								onChange={onChange}
								options={[
									{ optionValue: '0', optionLabel: 'Disabilitata' },
									{ optionValue: '1', optionLabel: 'Abilitata' },
								]}
								optionValue={(o) => o.optionValue}
								optionLabel={(o) => o.optionLabel}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col all={12}>
						<h5>Gestione invio mail</h5>
					</Col>
				</Row>
				<Row>
					{/* La notifica viene inviata anche tramite e-mail */}
					<Col all={6}>
						<FormGroup
							name="mail_flag"
							label="La notifica viene inviata anche tramite e-mail"
							validationState={validator.validationState.mail_flag}
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.mail_flag == 1}
							/>
						</FormGroup>
					</Col>
					{/* Gestione email riepilogativa delle notifiche inviate */}
					<Col all={6}>
						<FormGroup
							name="group_by_flag"
							label="Gestione email riepilogativa delle notifiche inviate"
							validationState={validator.validationState.group_by_flag}
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.group_by_flag == 1}
								disabled={editItem.mail_flag == 0}
							/>
						</FormGroup>
					</Col>
				</Row>
				{editItem.group_by_flag == 1 && <>
					<Row>
						{/* Giorni */}
						<Col all={4}>
							<FormGroup
								name="send_mail_days"
								label="Giorni"
								validationState={validator.validationState.send_mail_days}
							>
								<InputText
									type="number"
									value={editItem.send_mail_days || ''}
									onChange={onChange}
								/>
							</FormGroup>
						</Col>
						{/* Ore */}
						<Col all={4}>
							<FormGroup
								name="send_mail_hours"
								label="Ore"
								validationState={validator.validationState.send_mail_hours}
							>
								<InputText
									type="number"
									value={editItem.send_mail_hours || ''}
									onChange={onChange}
								/>
							</FormGroup>
						</Col>
						{/* Minuti */}
						<Col all={4}>
							<FormGroup
								name="send_mail_minutes"
								label="Minuti"
								validationState={validator.validationState.send_mail_minutes}
							>
								<InputText
									type="number"
									value={editItem.send_mail_minutes || ''}
									onChange={onChange}
								/>
							</FormGroup>
						</Col>
					</Row>
				</>}
				<Row>
					<Col all={12}>
						<h5>Gestione tempi di invio della notifica</h5>
					</Col>
				</Row>
				<Row>
					{/* Giorni */}
					<Col all={4}>
						<FormGroup
							name="days"
							label="Giorni"
							validationState={validator.validationState.days}
						>
							<InputText
								type="number"
								value={editItem.days || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Ore */}
					<Col all={4}>
						<FormGroup
							name="hours"
							label="Ore"
							validationState={validator.validationState.hours}
						>
							<InputText
								type="number"
								value={editItem.hours || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Minuti */}
					<Col all={4}>
						<FormGroup
							name="minutes"
							label="Minuti"
							validationState={validator.validationState.minutes}
						>
							<InputText
								type="number"
								value={editItem.minutes || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
