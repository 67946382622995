import React, { useEffect, useState } from 'react'
import { Col, FormGroup, Checkbox, InputText, InputTextarea, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ editItem, onChange, onSubmit, setEditItem }) {
	const [selectOptions, setSelectOptions] = useState({})
	const [defaultValues, setDefaultValues] = useState({})
	const [isSelect, setIsSelect] = useState(false)

	const handleListFilters = async() => {
		const refer_types = await http.get(`${window.__endpoint}/cha/referement_types/list?cols=refer_type_id,name,referment_full_name`)
		const applications = await http.get(`${window.__endpoint}/cha/applications/list?cols=application_id,description&order=name`)
		const modules = await http.get(`${window.__endpoint}/cha/modules/list?cols=module_id,name&order=name`)
		const profile_types = await http.get(`${window.__endpoint}/cha/list_lines/get/list?cols=list_value_code,list_value_id,name&list_id=${45}`)
		const list_values = await http.get(`${window.__endpoint}/cha/lists/list?cols=list_id,name&order=name`)
		const print_reports = await http.get(`${window.__endpoint}/cha/print_reports/list?cols=print_report_id,name&order=name`)

		setSelectOptions({
			...selectOptions,
			refer_types: refer_types.data,
			applications: applications.data,
			modules: modules.data,
			profile_types: profile_types.data,
			list_values: list_values.data,
			print_reports: print_reports.data
		})
	}

	const handleListFilterOptions = async(e) => {
		const key = e.target.name
		const { value } = e.target

		// https://stackoverflow.com/a/50753272/16425431
		let default_values, print_report_values = []
		switch (true) {
			case ((key == 'value_from_list_id' && (!value || value == 0)) && (key == 'value_from_referment_state' && (!value || value == 0))):
				// In questo caso, devo mostrare la label
				setIsSelect(false)
				onChange({ target: { name: 'default_value', value: '' } })
				break

			case key == 'value_from_list_id' && (value && value > 0): {
				// In questo caso, devo mostrare la select chiamando la list di list_values, passandogli il valore di list_id
				const response = await http.get(`${window.__endpoint}/cha/list_lines/get/list?cols=list_value_id,name&list_id=${value}`)

				default_values = response.data.map(item => ({ value: item.list_value_id, label: item.name }))

				setIsSelect(true)
				break
			}

			case key == 'value_from_referment_state' && value > 0: {
				// In questo caso, devo mostrare la select chiamando la list di pub_states, passandogli il valore di refer_type_id
				const response = await http.get(`${window.__endpoint}/cha/states/get/list?cols=refer_state_id,name&order=name&refer_type_id=${value}`)

				default_values = response.data.map(item => ({ value: item.refer_state_id, label: item.name }))

				setIsSelect(true)
				break
			}

			case ((key == 'profile_type_id' && value == 5) || (key == 'module_id' && value > 0)): {
				if (editItem.module_id > 0) {
					// In questo caso, devo mostrare la select chiamando la list di pub_states, passandogli il valore di refer_type_id
					const response = await http.get(`${window.__endpoint}/cha/print_reports/list?cols=print_report_id,name&order=name&module_id=${editItem.module_id}`)

					print_report_values = response.data

					setIsSelect(true)
				}
				break
			}

			default:
				setIsSelect(false)
				break
		}

		if (value == '') {
			delete editItem.default_value
		}

		setSelectOptions({
			...selectOptions,
			print_report_values: print_report_values
		})

		setDefaultValues(default_values)
	}

	useEffect(() => {
		handleListFilters()
	}, [])

	useEffect(() => {
		if (editItem.default_value != '' && editItem.default_value != null && editItem.default_value != undefined) {
			switch (true) {
				case editItem.value_from_list_id > 0:
					handleListFilterOptions({ target: { name: 'value_from_list_id', value: editItem.value_from_list_id } })
					break
				case editItem.value_from_refer_type_id > 0:
					handleListFilterOptions({ target: { name: 'value_from_refer_type_id', value: editItem.value_from_refer_type_id } })
					break
				case editItem.value_from_referment_state > 0:
					handleListFilterOptions({ target: { name: 'value_from_referment_state', value: editItem.value_from_referment_state } })
					break
			}
		}
	}, [editItem.default_value])

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name', 'application_id', 'module_id', 'description','profile_type_id']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar />
			<Row>
				<Col all={3}>
					<FormGroup
						name="name"
						label="Nome"
						validationState={validator.validationState.name}
					>
						<InputText
							value={editItem.name || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={3}>
					<FormGroup
						name="value_from_refer_type_id"
						label="Valore da referement type"
					>
						<Select
							value={editItem.value_from_refer_type_id || ''}
							onChange={(e) => {
								handleListFilterOptions(e)
								onChange(e)
							}}
							options={selectOptions.refer_types || []}
							optionValue={(o) => o.refer_type_id}
							optionLabel={(o) => o.referment_full_name}
							disabled={editItem.value_from_list_id || editItem.value_from_referment_state} // Disabilita se uno dei due sotto é selezionato
						/>
					</FormGroup>
				</Col>
				<Col all={3}>
					<FormGroup
						name="value_from_referment_state"
						label="Valore da stato referement type"
					>
						<Select
							value={editItem.value_from_referment_state || ''}
							onChange={(e) => {
								handleListFilterOptions(e)
								onChange(e)
							}}
							options={selectOptions.refer_types || []}
							optionValue={(o) => o.refer_type_id}
							optionLabel={(o) => o.referment_full_name}
							disabled={editItem.value_from_refer_type_id || editItem.value_from_list_id} // Disabilita se uno dei due sopra/sotto non é selezionato
						/>
					</FormGroup>
				</Col>
				<Col all={3}>
					<FormGroup
						name="value_from_list_id"
						label="Valore da lista"
					>
						<Select
							value={editItem.value_from_list_id || ''}
							onChange={(e) => {
								handleListFilterOptions(e)
								onChange(e)
							}}
							options={selectOptions.list_values || []}
							optionValue={(o) => o.list_id}
							optionLabel={(o) => o.name}
							disabled={editItem.value_from_refer_type_id || editItem.value_from_referment_state} // Disabilita se uno dei due sopra non é selezionato
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				{! isSelect ?
					<Col all={3}>
						<FormGroup
							name="default_value"
							label="Valore di default"
							validationState={validator.validationState.default_value}
						>
							<InputText
								value={editItem.default_value || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					:
					<Col all={3}>
						<FormGroup
							name="default_value"
							label="Valore di default"
							validationState={validator.validationState.default_value}
						>
							<Select
								value={editItem.default_value || ''}
								onChange={onChange}
								options={defaultValues || []}
								optionValue={(o) => o.value}
								optionLabel={(o) => o.label}
							/>
						</FormGroup>
					</Col>
				}
			</Row>
			<Row>
				<Col all={3}>
					<FormGroup
						name="application_id"
						label="Applicazione"
						validationState={validator.validationState.application_id}
					>
						<Select
							value={editItem.application_id || ''}
							onChange={(e) => {
								handleListFilterOptions(e)
								onChange(e)
							}}
							options={selectOptions.applications || []}
							optionValue={(o) => o.application_id}
							optionLabel={(o) => o.description}
						/>
					</FormGroup>
				</Col>
				<Col all={3}>
					<FormGroup
						name="module_id"
						label="Modulo"
						validationState={validator.validationState.module_id}
					>
						<Select
							value={editItem.module_id || ''}
							onChange={(e) => {
								handleListFilterOptions(e)
								onChange(e)
							}}
							options={selectOptions.modules || []}
							optionValue={(o) => o.module_id}
							optionLabel={(o) => o.name}
						/>
					</FormGroup>
				</Col>
				<Col all={3}>
					<FormGroup
						name="refer_type_id"
						label="Riferimento"
					>
						<Select
							value={editItem.refer_type_id || ''}
							onChange={(e) => {
								handleListFilterOptions(e)
								onChange(e)
							}}
							options={selectOptions.refer_types || []}
							optionValue={(o) => o.refer_type_id}
							optionLabel={(o) => o.referment_full_name}
						/>
					</FormGroup>
				</Col>
				<Col all={3}>
					<FormGroup
						name="profile_type_id"
						label="Tipo di profilo"
						validationState={validator.validationState.profile_type_id}

					>
						<Select
							value={editItem.profile_type_id || ''}
							onChange={(e) => {
								handleListFilterOptions(e)
								onChange(e)
							}}
							options={selectOptions.profile_types || []}
							optionValue={(o) => o.list_value_id}
							optionLabel={(o) => o.name}
						/>
					</FormGroup>
				</Col>
			</Row>
			{/* Se report (profile_type_id = 5) */}
			{(editItem.profile_type_id == 5 && selectOptions.print_report_values) && <Row>
				<Col all={12}>
					<FormGroup
						name="print_report_id"
						label="Print report"
					>
						<Select
							value={editItem.print_report_id || ''}
							onChange={(e) => {
								handleListFilterOptions(e)
								onChange(e)
							}}
							options={selectOptions.print_report_values || []}
							optionValue={(o) => o.print_report_id}
							optionLabel={(o) => o.name}
						/>
					</FormGroup>
				</Col>
			</Row>}
			{/* Checkboxes */}
			<Row>
				<Col all={6}>
					<FormGroup
						name="is_multi"
						label="Consenti selezione multipla di valori"
						validationState={validator.validationState.is_multi}
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.is_multi == 1}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="user_visibility_flag"
						label="Visibile all'utente"
						validationState={validator.validationState.user_visibility_flag}
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.user_visibility_flag == 1}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col all={12}>
					<FormGroup
						name="description"
						label="Descrizione"
						validationState={validator.validationState.description}
					>
						<InputTextarea
							value={editItem.description || ''}
							rows={4}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
		</form>}
	</FormValidator>
}

export default Edit
