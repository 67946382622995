import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

import { Edit as FieldEdit } from '../../Components/Fields'

function PageEditNode() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { field_id, id } = useParams()
	const isNew = (field_id == undefined)
	const [editItem, setEditItem] = useState({})

	useEffect(() => {
		handleLoad()
	}, [id])

	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleLoad = async() => {
		if (!isNew) {
			const area = await http.get(`${window.__endpoint}/rep/reports/fields/load?report_field_id=${field_id}`)
			setEditItem(area.data)
		}
	}

	const handleSave = async() => {
		if (isNew) {
			const field_id = await http.post(`${window.__endpoint}/rep/reports/fields/create`, {
				report_id: id,
				...editItem,
			})
			navigate(`./../edit/${field_id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/rep/reports/fields/update`, {
				report_field_id: field_id,
				report_id: id,
				...editItem,
			})
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	return <Card title={`${isNew ? 'Crea campo' : `Modifica ${editItem && editItem.column_name != '' ? editItem.column_name : 'campo'}`}`}>
		<FieldEdit
			report_id={field_id}
			editItem={editItem}
			onChange={handleChangeEditItem}
			onSubmit={handleSave}
		/>
	</Card>
}

export default PageEditNode
