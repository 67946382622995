import React from 'react'
import { ListGrid, ListToolbar } from 'components/List'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select, Button } from 'components/Ui'
import http from 'axios'

function List({ defaultFiltersValues, items, onDelete, onList, filtersValues, list_id, ...props }) {
	const { onSubmit } = props

	// Stati della lista
	const [referementTypes, setRefermentTypes] = React.useState([])
	const [filteredReferementTypes, setFilteredReferementTypes] = React.useState([])

	const [selectedReferType, setSelectedReferType] = React.useState()
	const [selectedReferId, setSelectedReferId] = React.useState()

	// Carico i tipi di referimento solo di cha
	const handleLoadReferTypes = async() => {
		const referTypes = await http.get(`${window.__endpoint}/cha/referement_types/list?cols=refer_type_id,referment_full_name&order=referment_full_name&object_visibility=1`)

		setRefermentTypes(referTypes.data)
	}

	// Carico i tipi di referimento filtrati per refer selezionato
	const handleLoadReferOptions = async() => {
		const filteredReferTypes = await http.get(`${window.__endpoint}/cha/modules/referement_data/referData?refer_type_id=${selectedReferType}`)

		setFilteredReferementTypes(filteredReferTypes.data)
	}

	React.useEffect(() => {
		handleLoadReferTypes()
	}, [])

	React.useEffect(() => {
		if (selectedReferType > 0)
			handleLoadReferOptions()
	}, [selectedReferType])

	const handleSubmit = () => {
		onSubmit({
			refer_type_id: selectedReferType,
			refer_id: selectedReferId,
			module_object_id: -1,
		})
		setSelectedReferType(null)
		setSelectedReferId(null)
	}

	return <React.Fragment>
		<ListToolbar
			{...props}
			hideBtnFilter
			hideBtnAdd
			onReload={() => {
				onList(filtersValues)
			}}
		/>
		<Row>
			<Col lg={12} md={12}>
				<Select
					value={selectedReferType || ''}
					onChange={(e) => setSelectedReferType(e.target.value)}
					options={referementTypes || []}
					optionValue={(o) => o.refer_type_id}
					optionLabel={(o) => o.referment_full_name}
					all={{
						label: ' - Seleziona - '
					}}
					filter
				/>
				<Select
					disabled={!selectedReferType}
					value={selectedReferId || ''}
					onChange={(e) => setSelectedReferId(e.target.value)}
					options={filteredReferementTypes || []}
					optionValue={(o) => o.refer_id}
					optionLabel={(o) => o.description}
					all={{
						label: ' - Seleziona - '
					}}
					filter
				/>
				<Button onClick={handleSubmit} disabled={!selectedReferId || !selectedReferType}>
					Aggiungi
				</Button>
			</Col>
		</Row>

		<ListGrid
			hideBtnEdit
			onDelete={onDelete}
			items={items}
			fieldId='module_object_id'
			columns={[
				{ field: 'module_object_id', header: 'Id', sortable: true },
				{ field: 'full_name', header: 'Nome', sortable: true },
				{ field: 'refer_id', header: 'refer_id', sortable: true },
			]}
		/>
	</React.Fragment>
}

export default List
