import React, { useEffect, useState } from 'react'
import { Col, FormGroup, Checkbox, InputText, InputTextarea, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ editItem, onChange, onSubmit, referTypeId }) {
	const [selectOptions, setSelectOptions] = useState({})

	const loadSelectOptions = async() => {
		const referment_types = await http.get(`${window.__endpoint}/cha/referement_types/list?cols=refer_type_id,referment_full_name,name`)

		setSelectOptions({
			referment_types: referment_types.data,
		})
	}

	useEffect(() => {
		loadSelectOptions()
	}, [])
	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar />
			<Row>
				<Col all={8}>
					<FormGroup
						name="name"
						label="Nome"
						validationState={validator.validationState.name}
					>
						<InputText
							value={editItem.name || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={4}>
					<FormGroup
						name="refer_type_id"
						label="Referment type"
					>
						<Select
							value={editItem.refer_type_id || ''}
							disabled = {referTypeId && referTypeId > 0}
							onChange={onChange}
							options={selectOptions.referment_types || []}
							optionValue={(o) => o.refer_type_id}
							optionLabel={(o) => o.referment_full_name}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col all={12}>
					<FormGroup
						name="description"
						label="Descrizione"
						validationState={validator.validationState.description}
					>
						<InputText
							value={editItem.description || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={4}>
					<FormGroup
						name="refer_state_id"
						label="Valore"
						validationState={validator.validationState.refer_state_id}
					>
						<InputText
							type="number"
							value={editItem.refer_state_id || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={4}>
					<FormGroup
						name="refer_state_dp_id"
						label="Valore (algoritmo double precision - valore esponenziale base 2)"
						validationState={validator.validationState.refer_state_dp_id}
					>
						<InputText
							type="number"
							value={editItem.refer_state_dp_id || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={4}>
					<FormGroup
						name="state_order"
						label="Ordine"
						validationState={validator.validationState.state_order}
					>
						<InputText
							type="number"
							value={editItem.state_order || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={4}>
					<FormGroup
						name="active_flag"
						label="Attivo"
						validationState={validator.validationState.active_flag}
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.active_flag == 1}
						/>
					</FormGroup>
				</Col>
				<Col all={4}>
					<FormGroup
						name="user_visibility_flag"
						label="Visibile ad utente"
						validationState={validator.validationState.user_visibility_flag}
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.user_visibility_flag == 1}
						/>
					</FormGroup>
				</Col>
			</Row>
		</form>}
	</FormValidator>
}

export default Edit
