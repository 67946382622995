import React, { useEffect, useState } from 'react'
import { Col, FormGroup, InputTextarea, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ moduleId, editItem, setEditItem, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const documents = await http.get(`${window.__endpoint}/cha/documents/list?cols=document_id,name&order=name`)
		const list_lines = await http.get(`${window.__endpoint}/cha/list_lines/get/list?cols=list_value_code,list_value_id,name&list_id=1`)
		const referements = await http.get(`${window.__endpoint}/cha/referement_types/list?cols=refer_type_id,referment_full_name&order=referment_full_name`)
		const causals = await http.get(`${window.__endpoint}/inv/transaction_causals/list?cols=transaction_causal_id,name&order=name`)

		setSelectOptions({
			documents: documents.data,
			list_lines: list_lines.data,
			referements: referements.data,
			causals: causals.data,
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['document_id','transaction_causal_id']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Documento */}
					<Col all={6}>
						<FormGroup
							name="document_id"
							label="Documento"
							validationState={validator.validationState.document_id}
						>
							<Select
								value={editItem.document_id || ''}
								onChange={onChange}
								options={selectOptions.documents || []}
								optionValue={(o) => o.document_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Causale (documento) */}
					<Col all={6}>
						<FormGroup
							name="document_causal_id"
							label="Causale del documento"
						>
							<Select
								value={editItem.document_causal_id || ''}
								onChange={onChange}
								options={selectOptions.list_lines || []}
								optionValue={(o) => o.list_value_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Riferimento */}
					<Col all={6}>
						<FormGroup
							name="child_refer_type_id"
							label="Riferimento"
						>
							<Select
								value={editItem.child_refer_type_id || ''}
								onChange={onChange}
								options={selectOptions.referements || []}
								optionValue={(o) => o.refer_type_id}
								optionLabel={(o) => o.referment_full_name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Causale (magazzino) */}
					<Col all={6}>
						<FormGroup
							name="transaction_causal_id"
							label="Causale di magazzino"
							validationState={validator.validationState.transaction_causal_id}
						>
							<Select
								value={editItem.transaction_causal_id || ''}
								onChange={onChange}
								options={selectOptions.causals || []}
								optionValue={(o) => o.transaction_causal_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Note */}
					<Col all={12}>
						<FormGroup
							name="note"
							label="Note"
							validationState={validator.validationState.note}
						>
							<InputTextarea
								rows={4}
								value={editItem.note || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
