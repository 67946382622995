import React, { useEffect, useState } from 'react'
import { Col, MessageBox, Row } from 'components/Ui'
import http from 'axios'

function Dashboard() {
	const [servers, setServers] = useState([])
	useEffect(() => {
		loadData()
	}, [])

	const loadData = async() => {
		const servers = await http.get(`${window.__endpoint}/server/cha/servers/loadDashboard`)
		setServers(servers.data.filter(server => !!server.data))
	}

	const getPercentage = (total, used) => {
		return Math.round((used * 100) / total)
	}

	const getColor = (percentage) => {
		if (percentage < 80) {
			return 'green'
		} else if (percentage < 90) {
			return 'yellow'
		} else {
			return 'red'
		}
	}

	return (
		<Row>
			{servers.map((server, s) => <Col all={12} lg={6} key={s}>
				<div className="card">
					<div className="flex justify-content-between align-items-center mb-5">
						<h5>{server.host_name}</h5>
					</div>
					<Row>
						<Col all={12} lg={6} key={server.data.cpu_number}>
							<p>
								<span className='text-900 font-medium text-xl'>Cpu</span><br/>
								{server.data.cpu_number} x <i className="pi pi-server text-xl"></i>
							</p>
						</Col>
						<Col all={12} lg={6} key={server.data.ram}>
							<p>
								<span className='text-900 font-medium text-xl'>Memoria ram</span><br/>
								{server.data.ram} Gb
							</p>
						</Col>
						<Col all={12}>
							<hr/>
							<span className='text-900 font-medium text-xl'>Stato servizi</span><br/>
						</Col>
						<Col all={12} lg={6} key={server.data.socket}>
							<MessageBox
								severity={server.data.socket_alive ? 'success' : 'error'}
								text={`Socket ${server.data.socket_alive ? 'attivo' : 'non raggiungibile'}`}
							/>
						</Col>
						<Col all={12} lg={6} key={server.data.cron}>
							<MessageBox
								severity={server.data.cron_alive ? 'success' : 'error'}
								text={`Cron ${server.data.cron_alive ? 'attivo' : 'non attivo'}`}
							/>
						</Col>
					</Row>
					{['srv', 'var'].map((partition, p) => {
						const percentage = getPercentage(server.data[`${partition}_space_total`], server.data[`${partition}_space_used`])
						return <Row key={p}>
							<Col all={12}>
								<hr/>
								<p>
									<span className="text-900 font-medium text-xl">Spazio sulla partizione /{partition} (Gb)</span><br/>
									<span className="mt-1 text-600">Totale: {server.data[`${partition}_space_total`]}</span><br/>
									<span className="mt-1 text-600">Utilizzato: {server.data[`${partition}_space_used`]}</span><br/>
									<span className="mt-1 text-600">Libero: {server.data[`${partition}_space_free`]}</span>
								</p>
								<div className="surface-300 border-round overflow-hidden" style={{ height: 8 }}>
									<div className={`bg-${getColor(percentage)}-500 h-full`} style={{ width: `${percentage}%` }}>
									</div>
								</div>
								<p className={`text-${getColor(percentage)}-500 text-center font-medium`}>{percentage} %</p>
							</Col>
						</Row>
					})}
				</div>
			</Col>)}
		</Row>
	)
}

export default Dashboard
