import React, { useEffect, useState } from 'react'
import { Col, FormGroup, InputText, InputTextarea, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'
import { filter, forEach } from 'lodash'

function Edit({ moduleId, editItem, onMultipleChange, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})
	const [selectDocumentTypesOptions, setSelectDocumentTypesOptions] = useState({})

	useEffect(() => {
		loadSelectOptions()
	}, [])

	useEffect(() => {
		loadDocumentTypes(editItem.refer_type_id)
	}, [editItem.refer_type_id])
	const loadSelectOptions = async() => {
		const referements = await http.get(`${window.__endpoint}/cha/referement_types/list?cols=refer_type_id,referment_full_name&order=referment_full_name`)

		/*
		const filteredReferements = referements.data.filter((referement) =>
			referement.refer_type_id == 6 ||
			referement.refer_type_id == 9 ||
			referement.refer_type_id == 14 ||
			referement.refer_type_id == 16)
		*/

		const sectionals = await http.get(`${window.__endpoint}/cha/sectionals/list?cols=sectional_id,name&order=name`)

		setSelectOptions({
			referements: referements.data,
			sectionals: sectionals.data,
			document_types: []
		})
	}

	const loadDocumentTypes = async(referement) => {
		let data = {}
		switch (referement) {
			case '6':
				data = await http.get(`${window.__endpoint}/sal/orderTypes/list?cols=order_type_id,name&order=name`)
				break
			case '9':
				data = await http.get(`${window.__endpoint}/pur/orderTypes/list?cols=order_type_id,name&order=name`)
				break
			case '14':
				data = await http.get(`${window.__endpoint}/act/invoiceTypes/list?cols=invoice_type_id,name&order=name`)
				break
			case '16':
				data = await http.get(`${window.__endpoint}/act/payableInvoiceTypes/list?cols=payable_invoice_type_id,name&order=name`)
				break
			default:
				data.data = null
		}
		setSelectDocumentTypesOptions({
			document_types: data.data,
		})
	}
	const handleReferementChange = async(e) => {
		const referement = e.target.value
		loadDocumentTypes(referement)
		onMultipleChange({
			...editItem,
			document_type_id: null,
			refer_type_id: e.target.value,
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name','code','description','refer_type_id']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Nome */}
					<Col all={12}>
						<FormGroup
							name="name"
							label="Nome"
							validationState={validator.validationState.name}
						>
							<InputText
								value={editItem.name || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Descrizione */}
					<Col all={12}>
						<FormGroup
							name="description"
							label="Descrizione"
							validationState={validator.validationState.description}
						>
							<InputTextarea
								value={editItem.description || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Riferimento */}
					<Col all={6}>
						<FormGroup
							name="refer_type_id"
							label="Riferimento"
							validationState={validator.validationState.refer_type_id}

						>
							<Select
								value={editItem.refer_type_id || ''}
								onChange={handleReferementChange}
								options={selectOptions.referements || []}
								optionValue={(o) => o.refer_type_id}
								optionLabel={(o) => o.referment_full_name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Tipologia documento */}
					<Col all={6}>
						<FormGroup
							name="document_type_id"
							label="Tipologia documento"
						>
							<Select
								value={editItem.document_type_id || ''}
								onChange={onChange}
								options={selectDocumentTypesOptions.document_types || []}
								optionValue={(o) => o.order_type_id || o.invoice_type_id || o.payable_invoice_type_id}
								optionLabel={(o) => o.name}
								disabled={!editItem.refer_type_id > 0}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Codice */}
					<Col all={6}>
						<FormGroup
							name="code"
							label="Codice"
							validationState={validator.validationState.code}
						>
							<InputText
								value={editItem.code || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Sezionale di default */}
					<Col all={6}>
						<FormGroup
							name="default_sectional_id"
							label="Sezionale di default"
						>
							<Select
								value={editItem.default_sectional_id || ''}
								onChange={onChange}
								options={selectOptions.sectionals || []}
								optionValue={(o) => o.sectional_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
