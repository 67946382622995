import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * modules
import { default as Areas } from '../modules/Areas'
import { default as Lists } from '../modules/Lists'
import { default as Modules } from '../modules/Modules'
import { default as Referements } from '../modules/Referements'
import { default as Texts } from '../modules/Texts'
import { default as TextTranslations } from '../modules/TextTranslations'
import { default as Profiles } from '../modules/Profiles'
import { default as Roles } from '../modules/Roles'
import { default as RegistrationApp } from '../modules/RegistrationApp'
import { default as Sectionals } from '../modules/Sectionals'
import { default as Documents } from '../modules/Documents'
import { default as NotificationModels } from '../modules/NotificationModels'
import { default as Concurrents } from '../modules/Concurrents'
import { default as Parameters } from '../modules/Parameters'
import { default as PrintReports } from '../modules/PrintReports'
import { default as Applications } from '../modules/Applications'
import ReferementsTranslations from '../modules/ReferementsTranslations'
import TriggerConnectors from '../modules/TriggerConnectors'
import TriggerActions from '../modules/TriggerActions'
import PrintReportsThemes from '../modules/PrintReportsThemes'
import PrintReportFormats from '../modules/PrintReportFormats'

function App() {
	return <Routes>
		<Route path="applications/*" element={<Applications />} />
		<Route path="areas/*" element={<Areas />} />
		<Route path="lists/*" element={<Lists />} />
		<Route path="modules/*" element={<Modules />} />
		<Route path="referements/*" element={<Referements />} />
		<Route path="referements-translations/*" element={<ReferementsTranslations />} />
		<Route path="texts/*" element={<Texts />} />
		<Route path="text-translations/*" element={<TextTranslations />} />
		<Route path="profiles/*" element={<Profiles />} />
		<Route path="roles/*" element={<Roles />} />
		<Route path="registration-apps/*" element={<RegistrationApp />} />
		<Route path="sectionals/*" element={<Sectionals />} />
		<Route path="documents/*" element={<Documents />} />
		<Route path="notification-models/*" element={<NotificationModels />} />
		<Route path="concurrents/*" element={<Concurrents />} />
		<Route path="parameters/*" element={<Parameters />} />
		<Route path="print-reports/*" element={<PrintReports />} />
		<Route path="print-reports-themes/*" element={<PrintReportsThemes />} />
		<Route path="print-report-formats/*" element={<PrintReportFormats />} />
		<Route path="trigger-connectors/*" element={<TriggerConnectors />} />
		<Route path="trigger-actions/*" element={<TriggerActions />} />

	</Routes>
}

export default App
